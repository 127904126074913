import { LogoColor } from 'shared/types/frontendTypes'

interface LogoProps {
  color?: LogoColor
  className?: string
}

const Logo = ({ color = 'GRAY', className }: LogoProps) => {
  const colorString = (() => {
    switch (color) {
      case 'WHITE':
        return '#FFFFFF'
      case 'GRAY':
        return '#E9E9EB'
      case 'BLUE':
        return '#D2E0FF'
      case 'GREEN':
        return '#CCF8D2'
      case 'ORANGE':
        return '#FFE9C0'
      case 'PINK':
        return '#FED5CE'
      case 'PURPLE':
        return '#EED9FF'
      default:
        return '#FFFFFF'
    }
  })()

  if (color === 'GLOW') return glowLogo(className)

  // return (
  //   <svg
  //     className={className}
  //     width="102"
  //     height="52"
  //     viewBox="0 0 102 52"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g filter="url(#a)">
  //       <path
  //         d="M13.031 23.81c6.096 0 11.037-4.893 11.037-10.928h56.706c6.191 0 11.21 5.019 11.21 11.21v15.182c-.031 2.743.823 7.359 5.016 7.359h-3.055c-1.21 0-4.104-.251-6.545-2.142a11.16 11.16 0 0 1-6.626 2.167H23.927c-6.19 0-11.21-5.019-11.21-11.21V24.092q0-.144.004-.287.155.005.31.005"
  //         fill={colorString}
  //       />
  //     </g>
  //     <path
  //       d="M29.624 35.014q-1.374 0-2.486-.619a4.6 4.6 0 0 1-1.745-1.767q-.636-1.149-.635-2.652 0-1.485.652-2.634a4.5 4.5 0 0 1 1.781-1.768q1.129-.62 2.522-.619 1.392 0 2.521.619a4.5 4.5 0 0 1 1.781 1.768q.652 1.149.652 2.634t-.67 2.634a4.7 4.7 0 0 1-1.834 1.785q-1.145.62-2.539.62m0-1.75q.777 0 1.446-.371a2.8 2.8 0 0 0 1.111-1.114q.423-.742.423-1.803T32.2 28.19q-.406-.742-1.076-1.114a2.94 2.94 0 0 0-1.446-.37q-.776 0-1.445.37-.653.373-1.04 1.114-.39.725-.389 1.786 0 1.573.794 2.44.81.848 2.027.848m11.931-8.309q1.147 0 2.046.477.916.478 1.428 1.415.51.937.511 2.263v5.745H43.55V29.41q0-1.308-.652-1.998-.654-.707-1.781-.707-1.129 0-1.799.707-.652.69-.652 1.998v5.445h-2.01v-9.74h2.01v1.113q.493-.6 1.252-.937a4.1 4.1 0 0 1 1.64-.336m23.055 0q1.146 0 2.045.477.917.478 1.428 1.415.53.937.53 2.263v5.745h-1.993V29.41q0-1.308-.653-1.998-.651-.707-1.78-.707t-1.799.707q-.652.69-.652 1.998v5.445h-1.993V29.41q0-1.308-.652-1.998-.653-.707-1.781-.707-1.129 0-1.799.707-.652.69-.652 1.998v5.445h-2.01v-9.74h2.01v1.113q.493-.6 1.252-.937a4 4 0 0 1 1.622-.336q1.165 0 2.08.495.918.495 1.411 1.432.441-.884 1.376-1.397a4 4 0 0 1 2.01-.53m15.465 4.791q0 .548-.07.99h-7.424q.088 1.166.864 1.874.776.707 1.904.707 1.623 0 2.292-1.361h2.17a4.4 4.4 0 0 1-1.605 2.21q-1.147.848-2.857.848-1.392 0-2.504-.619a4.6 4.6 0 0 1-1.728-1.767q-.617-1.149-.617-2.652t.6-2.634a4.34 4.34 0 0 1 1.71-1.768q1.11-.62 2.54-.619 1.374 0 2.45.601t1.675 1.697q.6 1.08.6 2.493m-2.099-.636q-.017-1.114-.793-1.786t-1.922-.672q-1.04 0-1.78.672-.742.654-.882 1.786z"
  //       fill="#000"
  //     />
  //     <g filter="url(#b)">
  //       <path
  //         d="M22.511 13.109c0 5.124-4.144 9.28-9.255 9.28S4 18.233 4 13.108s4.144-9.28 9.256-9.28c5.111 0 9.255 4.155 9.255 9.28"
  //         fill={colorString}
  //       />
  //     </g>
  //     <path
  //       d="M17.018 10.841c-.336-.688-1.305-1.25-2.431-.923a2.48 2.48 0 0 0-1.332.943 2.48 2.48 0 0 0-1.333-.943c-1.129-.323-2.094.235-2.43.923-.472.964-.277 2.047.581 3.22.673.919 1.634 1.85 3.029 2.93a.25.25 0 0 0 .308 0c1.393-1.078 2.356-2.001 3.028-2.93.856-1.173 1.052-2.256.58-3.22"
  //       fill="#E3407C"
  //     />
  //     <defs>
  //       <filter
  //         id="a"
  //         x="8.968"
  //         y="9.132"
  //         width="92.282"
  //         height="41.775"
  //         filterUnits="userSpaceOnUse"
  //         colorInterpolationFilters="sRGB"
  //       >
  //         <feFlood floodOpacity="0" result="BackgroundImageFix" />
  //         <feColorMatrix
  //           in="SourceAlpha"
  //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
  //           result="hardAlpha"
  //         />
  //         <feOffset dx=".25" dy=".25" />
  //         <feGaussianBlur stdDeviation="2" />
  //         <feComposite in2="hardAlpha" operator="out" />
  //         <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
  //         <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_16_4" />
  //         <feBlend
  //           in="SourceGraphic"
  //           in2="effect1_dropShadow_16_4"
  //           result="shape"
  //         />
  //       </filter>
  //       <filter
  //         id="b"
  //         x=".25"
  //         y=".079"
  //         width="26.511"
  //         height="26.559"
  //         filterUnits="userSpaceOnUse"
  //         colorInterpolationFilters="sRGB"
  //       >
  //         <feFlood floodOpacity="0" result="BackgroundImageFix" />
  //         <feColorMatrix
  //           in="SourceAlpha"
  //           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
  //           result="hardAlpha"
  //         />
  //         <feOffset dx=".25" dy=".25" />
  //         <feGaussianBlur stdDeviation="2" />
  //         <feComposite in2="hardAlpha" operator="out" />
  //         <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
  //         <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_16_4" />
  //         <feBlend
  //           in="SourceGraphic"
  //           in2="effect1_dropShadow_16_4"
  //           result="shape"
  //         />
  //       </filter>
  //     </defs>
  //   </svg>
  // )

  return (
    <svg
      className={className}
      width="117"
      height="56"
      viewBox="0 0 117 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="12.601"
        cy="12.634"
        rx="12.601"
        ry="12.634"
        fill={colorString}
      />
      <path
        fill={colorString}
        d="M110.175 45.589v-7.201l-7.381 12.371c3.197 3.891 7.997 4.34 9.787 4.34h3.745c-5.39 0-6.295-6.24-6.151-9.51"
      />
      <path
        fill={colorString}
        d="M13.002 26.992q-.008.234-.008.47v13.924c0 7.59 6.153 13.743 13.743 13.743h69.697c7.59 0 13.743-6.153 13.743-13.743V27.463c0-7.59-6.153-13.744-13.743-13.744H26.949c-.605 7.274-6.579 13.022-13.947 13.272"
      />
      <path
        d="M33.706 40.198q-1.76 0-3.168-.77a5.8 5.8 0 0 1-2.222-2.222q-.792-1.43-.792-3.3t.814-3.3a5.73 5.73 0 0 1 2.266-2.2q1.43-.792 3.19-.792t3.19.792a5.6 5.6 0 0 1 2.244 2.2q.836 1.43.836 3.3t-.858 3.3a5.85 5.85 0 0 1-2.288 2.222q-1.43.77-3.212.77m0-2.684q.837 0 1.562-.396a3 3 0 0 0 1.188-1.232q.44-.815.44-1.98 0-1.738-.924-2.662-.902-.946-2.222-.946t-2.222.946q-.88.924-.88 2.662t.858 2.684q.88.924 2.2.924m15.372-9.878q2.178 0 3.52 1.386 1.341 1.364 1.342 3.828V40h-3.08v-6.732q0-1.452-.726-2.222-.726-.792-1.98-.792-1.277 0-2.024.792-.726.77-.726 2.222V40h-3.08V27.812h3.08v1.518q.615-.792 1.562-1.232a4.84 4.84 0 0 1 2.112-.462m28.169 0q2.243 0 3.608 1.386 1.386 1.364 1.386 3.828V40h-3.08v-6.732q0-1.43-.726-2.178-.726-.77-1.98-.77t-2.002.77q-.726.748-.726 2.178V40h-3.08v-6.732q0-1.43-.726-2.178-.726-.77-1.98-.77-1.276 0-2.024.77-.726.748-.726 2.178V40h-3.08V27.812h3.08v1.474a4.1 4.1 0 0 1 1.518-1.21q.945-.44 2.068-.44 1.43 0 2.552.616a4.17 4.17 0 0 1 1.738 1.716q.594-1.056 1.716-1.694a5 5 0 0 1 2.464-.638m19.246 6.006q0 .66-.087 1.188h-8.91q.109 1.32.924 2.068.814.748 2.001.748 1.716 0 2.442-1.474h3.323q-.529 1.76-2.025 2.904-1.496 1.122-3.674 1.122-1.76 0-3.167-.77a5.67 5.67 0 0 1-2.178-2.222q-.77-1.43-.77-3.3 0-1.892.77-3.322a5.37 5.37 0 0 1 2.156-2.2q1.386-.77 3.19-.77 1.737 0 3.102.748a5.16 5.16 0 0 1 2.133 2.134q.77 1.364.77 3.146m-3.19-.88q-.021-1.188-.858-1.892-.836-.726-2.045-.726-1.145 0-1.937.704-.77.682-.945 1.914z"
        fill="#000"
      />
      <ellipse
        cx="12.601"
        cy="12.634"
        rx="12.601"
        ry="12.634"
        fill={colorString}
      />
      <path
        d="M18.62 9.167c-.532-1.08-2.065-1.965-3.85-1.45a3.92 3.92 0 0 0-2.11 1.482 3.92 3.92 0 0 0-2.11-1.482c-1.788-.508-3.317.37-3.85 1.45-.747 1.514-.437 3.216.922 5.06 1.065 1.443 2.587 2.905 4.796 4.604a.4.4 0 0 0 .489 0c2.205-1.695 3.731-3.146 4.796-4.604 1.355-1.844 1.665-3.546.918-5.06"
        fill="#E3407C"
      />
    </svg>
  )
}

const glowLogo = (className?: string) => (
  <svg
    className={className}
    width="97"
    height="52"
    viewBox="0 0 97 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <g opacity=".9">
        <g filter="url(#b)">
          <circle cx="13.5" cy="13.5" r="10.5" fill="#D7D8FF" />
        </g>
        <g filter="url(#c)">
          <path d="M51 28h42v12a8 8 0 0 1-8 8H51z" fill="#FFD8FE" />
        </g>
        <g filter="url(#d)">
          <path d="M50 12h35a8 8 0 0 1 8 8v12H50z" fill="#FFD9CD" />
        </g>
        <g filter="url(#e)">
          <path d="M12 28h43v20H20a8 8 0 0 1-8-8z" fill="#D5EEFF" />
        </g>
        <g filter="url(#f)">
          <path d="M12 28c0-8.837 7.163-16 16-16h26v20H12z" fill="#D7D8FF" />
        </g>
        <g filter="url(#g)">
          <rect
            x="91"
            y="48"
            width="3"
            height="3"
            rx="1.5"
            transform="rotate(-90 91 48)"
            fill="#FFD8FE"
          />
        </g>
      </g>
      <g filter="url(#h)">
        <path
          d="M13.031 23.98c6.096 0 11.037-4.892 11.037-10.926h56.706c6.191 0 11.21 5.018 11.21 11.209v15.182c-.031 2.743.823 7.359 5.016 7.359h-3.055c-1.21 0-4.104-.251-6.545-2.142a11.16 11.16 0 0 1-6.626 2.167H23.927c-6.19 0-11.21-5.019-11.21-11.21V24.263q0-.144.004-.286.155.004.31.004"
          fill="#FFF"
        />
      </g>
      <path
        d="M29.624 35.185q-1.374 0-2.486-.618a4.6 4.6 0 0 1-1.745-1.768q-.636-1.149-.635-2.652 0-1.485.652-2.634a4.5 4.5 0 0 1 1.781-1.768q1.129-.62 2.522-.619 1.392 0 2.521.619a4.5 4.5 0 0 1 1.781 1.768q.652 1.149.652 2.634t-.67 2.634a4.7 4.7 0 0 1-1.834 1.786q-1.145.618-2.539.618m0-1.75q.777 0 1.446-.371a2.8 2.8 0 0 0 1.111-1.114q.423-.742.423-1.803t-.405-1.786q-.406-.743-1.076-1.113a2.94 2.94 0 0 0-1.446-.372q-.776 0-1.445.372-.653.37-1.04 1.113-.39.726-.389 1.786 0 1.573.794 2.44.81.848 2.027.848m11.932-8.309q1.147 0 2.046.477.916.478 1.428 1.415.51.937.511 2.263v5.745H43.55v-5.445q0-1.308-.652-1.997-.654-.708-1.781-.708-1.129 0-1.799.708-.652.69-.652 1.997v5.445h-2.01v-9.74h2.01v1.113q.493-.6 1.252-.937a4.1 4.1 0 0 1 1.64-.336m23.055 0q1.146 0 2.045.477.917.478 1.428 1.415.53.937.53 2.263v5.745h-1.993v-5.445q0-1.308-.653-1.997-.651-.708-1.78-.708t-1.799.707q-.652.69-.652 1.998v5.445h-1.993v-5.445q0-1.308-.652-1.997-.653-.708-1.781-.708-1.129 0-1.799.707-.652.69-.652 1.998v5.445h-2.01v-9.74h2.01v1.113q.493-.6 1.252-.937a4 4 0 0 1 1.622-.336q1.165 0 2.08.495.918.495 1.411 1.432.441-.885 1.376-1.396a4 4 0 0 1 2.01-.53m15.465 4.79q0 .547-.07.99h-7.424q.088 1.167.864 1.874t1.904.707q1.623 0 2.292-1.361h2.17a4.4 4.4 0 0 1-1.605 2.21q-1.147.849-2.857.848-1.392 0-2.504-.618a4.6 4.6 0 0 1-1.728-1.768q-.617-1.149-.617-2.652t.6-2.634a4.34 4.34 0 0 1 1.71-1.768q1.11-.62 2.54-.619 1.374 0 2.45.601 1.076.602 1.675 1.697.6 1.08.6 2.493m-2.099-.636q-.017-1.114-.793-1.786t-1.922-.672q-1.04 0-1.78.672-.742.654-.882 1.786z"
        fill="#000"
      />
      <g filter="url(#i)">
        <path
          d="M22.511 13.28c0 5.125-4.144 9.28-9.255 9.28S4 18.404 4 13.28 8.144 4 13.256 4c5.111 0 9.255 4.155 9.255 9.28"
          fill="#FFF"
        />
      </g>
      <path
        d="M17.018 11.012c-.336-.688-1.305-1.25-2.431-.923a2.48 2.48 0 0 0-1.332.943 2.48 2.48 0 0 0-1.333-.943c-1.129-.323-2.094.235-2.43.923-.472.964-.277 2.047.581 3.22.673.919 1.634 1.85 3.029 2.93a.25.25 0 0 0 .308 0c1.393-1.078 2.356-2.001 3.028-2.93.856-1.173 1.052-2.256.58-3.22"
        fill="#E3407C"
      />
    </g>
    <defs>
      <filter
        id="b"
        x="-5"
        y="-5"
        width="37"
        height="37"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="4"
          result="effect1_foregroundBlur_152_365"
        />
      </filter>
      <filter
        id="c"
        x="43"
        y="20"
        width="58"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="4"
          result="effect1_foregroundBlur_152_365"
        />
      </filter>
      <filter
        id="d"
        x="42"
        y="4"
        width="59"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="4"
          result="effect1_foregroundBlur_152_365"
        />
      </filter>
      <filter
        id="e"
        x="4"
        y="20"
        width="59"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="4"
          result="effect1_foregroundBlur_152_365"
        />
      </filter>
      <filter
        id="f"
        x="4"
        y="4"
        width="58"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="4"
          result="effect1_foregroundBlur_152_365"
        />
      </filter>
      <filter
        id="g"
        x="87"
        y="41"
        width="11"
        height="11"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_152_365"
        />
      </filter>
      <filter
        id="h"
        x="8.968"
        y="9.303"
        width="92.282"
        height="41.775"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx=".25" dy=".25" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_152_365" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_152_365"
          result="shape"
        />
      </filter>
      <filter
        id="i"
        x=".25"
        y=".25"
        width="26.511"
        height="26.559"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx=".25" dy=".25" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_152_365" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_152_365"
          result="shape"
        />
      </filter>
      <clipPath id="a">
        <path fill="#FFF" d="M0 0h97v52H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default Logo
